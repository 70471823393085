$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    headerNavHeight = $(".js-header").outerHeight() + $(".js-sticky-nav").outerHeight() - 2;

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - headerNavHeight
        }, 1000);
    }





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $(".js-nav-section"),
        $nav = $(".js-nav"),
        navHeight = $(".js-header").outerHeight() + 15;

    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop() + 120;

        $sections.each(function() {
            var top = $(this).offset().top - navHeight,
                bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                $nav.find('li').removeClass('active');
                $sections.removeClass('active');
                $(this).addClass('active');
                $nav.find('a[href*="#'+$(this).attr('id')+'"]').parent('li').addClass('active');
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carousel
    // *
    // *
    var $carousel = $(".js-carousel");

    if ($carousel.length) {
        $carousel.each(function(){

            var $carouselWrapper = $(this).parents(".js-carousel-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: true,
                arrows: true,
                autoplay: false,
                adaptiveHeight: true,
                variableWidth: true,
                centerMode: true,
                appendDots: $carouselWrapper.find(".js-carousel-dots"),
                prevArrow: $(this).parents(".js-carousel-wrapper").find(".js-carousel-prev"),
                nextArrow: $(this).parents(".js-carousel-wrapper").find(".js-carousel-next"),
            });

        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carousel
    // *
    // *
    var $logoCarousel = $(".js-logo-carousel");

    if ($logoCarousel.length) {
        $logoCarousel.each(function(){

            var $carouselWrapper = $(this).parents(".js-logo-carousel-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: false,
                arrows: false,
                slidesToScroll: 2,
                autoplay: false,
                variableWidth: true,
                appendDots: $carouselWrapper.find(".js-logo-carousel-dots"),
            });

        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function(){

            var $sliderWrapper = $(this).parents(".js-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                draggable: false,
                arrows: false,
                autoplay: false,
                appendDots: $sliderWrapper.find(".js-slider-dots"),
            });

        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * link list button on mobile
    // *
    // *

    // detect touch screen
    if(('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {

        $(".js-link-list-link").click(function() {

            // first click only toggles image
            if (!$(this).hasClass("open")) {
                event.preventDefault();
                $(".js-link-list-link").removeClass("active open");
                $(this).addClass("active");
            }

            $(this).addClass("open");
        })
    };


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * headline video start on hover
    // *
    // *
    // play inner video
    function hoverVideos(e) {
        $(this).find('.js-headline').each(function(){
            $(this).get(0).play();
        });
    }

    // stop inner video
    function hideVideos(e) {
        $(this).find('.js-headline').each(function(){
            $(this).get(0).pause();
        });
    }

    if(window.innerWidth > 700){
        // fire functions when hovering element
        $(".js-headline-outer").hover( hoverVideos, hideVideos );

        // trigger hover on first element
        $(".js-nav").find(".js-headline-outer").eq(0).trigger("mouseenter");

    } else {
        console.log("funktioniert");

        $(".headline").removeAttr("autoplay playsinline").attr("preload","none");
    };


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMenu
    // *
    // *
    $(".js-navmenu-button").click(function() {
        $("body").toggleClass("freeze");
        $(".js-navmenu").toggleClass("active");
        $(".js-header-icon").toggleClass("hide");
    })






});

$(window).on("load resize", function (e) {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * newsTeaser
    // *
    // *
    var $news = $(".js-news");

    if ($news.length) {
        var isoOptions = {
            itemSelector: '.js-news-item',
            masonry: {
                columnWidth: '.js-news-item',
            }
        };

        $news.imagesLoaded().progress( function() {
            $news.isotope(isoOptions);
        });
    }


});
